import React from 'react';
import { FetchAPI } from '../util/FetchAPI';
import HistoryList from '../components/HistoryList';
import DashboardLocation from '../components/DashboardLocation';
import TeamMembersList from '../components/TeamMembersList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import {
  useNavigate
} from "react-router-dom";
import { Typography } from '@mui/material';
import NewLocationDialog from '../components/NewLocationDialog';
import HistoryFilter from '../components/HistoryFilter';
import dayjs from 'dayjs';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Dashboard = () => {
  const navigate = useNavigate();

  const [currentLocation, setCurrentLocation] = React.useState(-1);
  const [locations, setLocations] = React.useState([]);
  const [incomingCheck, setIncomingCheck] = React.useState(true);
  const [outgoingCheck, setOutgoingCheck] = React.useState(true);
  const [value, setValue] = React.useState(0);
  
  const [historyData, setHistoryData] = React.useState([]);
  
  const fetchLocations = async () => {
    const res = await FetchAPI("/locations", 'GET', '', localStorage.getItem("token"));
    setLocations(res.data);
  }

  const fetchHistory = async (locationid, dateFrom = dayjs().format('YYYY-MM-DD'), dateTo = dayjs().format('YYYY-MM-DD')) => {
    const res = await FetchAPI(`/history/${locationid}?d1=${dateFrom}&d2=${dateTo}`, 'GET', '', localStorage.getItem("token"));
    setHistoryData(res.data);
  }
  
  const handleTabsChange = (event, newValue) => {
    setValue(newValue);
  };

  const viewMovements = (locationId) => {
    setCurrentLocation(locationId);
    fetchHistory(locationId);
    setValue(1); // Change to movements tab
  };

  
  const handleLogout = async () => {
    await FetchAPI(`/logout`, 'POST', {}, localStorage.getItem("token"));
    localStorage.removeItem("token");
    navigate(`/login/${localStorage.getItem("company")}`);
  };

  React.useEffect(() => {
    //FetchJB(194253408260)
    fetchLocations();
  }, []);

  React.useEffect(() => {
    
  }, [incomingCheck, outgoingCheck]);


  return (
    <Box sx={{ width: '100%' }}>
      {/*//////////////////////////////////////////
      //  
      //  TABS HEADER 
      */}
      <Box sx={{ 
            borderBottom: 1, 
            borderColor: '#CCC', 
            backgroundColor: '#FFF', 
            display: 'flex', 
            flexDirection: 'row', 
            paddingLeft: '20px',
            paddingRight: '20px' }}>
        <Box 
          color="inherit" 
          sx={{marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto'}}>STOCKMAN
        </Box>

        <Tabs value={value} onChange={handleTabsChange}>
          <Tab label="Locations" {...a11yProps(0)} />
          <Tab label="Movements" {...a11yProps(1)} />
          <Tab label="Settings" {...a11yProps(2)} />
        </Tabs>

        <Button 
          color="inherit" 
          sx={{float: 'right', right: '0', marginLeft: 'auto'}}
          onClick={handleLogout}>logout
        </Button>
      </Box>

      {/*//////////////////////////////////////////
      //  
      //  LOCATIONS TAB
      */}
      <TabPanel value={value} index={0}>
        {locations.map((location, index) => {
          return (
            <DashboardLocation
              refresh={fetchLocations}
              key={index}
              index={index}
              location={location}
              triggerMovementsCallback={viewMovements} />);
        })}
        <NewLocationDialog refresh={fetchLocations} />
      </TabPanel>


      {/*//////////////////////////////////////////
      //  
      //  STOCK MOVEMENTS PANEL 
      */}
      <TabPanel value={value} index={1}>
        <Grid container columnSpacing={4}>  
          <Grid item xs={12} md={4} xl={3}>
            <HistoryFilter
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation} 
              fetchHistory={fetchHistory}
              locations={locations} 
              incomingCheck={incomingCheck}
              outgoingCheck={outgoingCheck}
              setIncomingCheck={setIncomingCheck}
              setOutgoingCheck={setOutgoingCheck}
              />
          </Grid>

          <Grid item xs={12} md={8} xl={6} sx={{ maxHeight: { xs: 'auto', md: 'calc(100vh - 100px)' }, overflowY: { xs: 'hidden', md: 'auto' }, overflowX: 'hidden'}}>
            <HistoryList historyData={historyData} />
          </Grid>

        </Grid>        
      </TabPanel>


      {/*//////////////////////////////////////////
      //  
      //  SYSTEM SETTINGS PANEL 
      */}
      <TabPanel value={value} index={2}>
        <Grid container justifyContent="center" columnSpacing={4}>  
          <Grid item xs={12} md={4}>
            <TeamMembersList />
          </Grid>
        </Grid>
      </TabPanel>
    </Box>
  );
}

export default Dashboard;
