import React from "react";
//import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Box from '@mui/material/Box';


function App() {
  //const [data, setData] = React.useState("Not Found");
  //const [stopStream, setStopStream] = React.useState(false);

  return (
    <Box sx={{ height: '100px', width: '100%', overflowY: 'hidden', margin: 0 }}>
      {/* <BarcodeScannerComponent
        width={'100%'}
        height={'auto'}
        stopStream={stopStream}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            setStopStream(false);
          } else {
            setData("Not Found");
          }
        }}
      />
      <p>{data}</p> */}
    </Box>
  );
}

export default App;
