import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';

const Switch = ({setMovementDirection}) => {
  const [isIncoming, setIsIncoming] = React.useState(true);

  const handleIn = () => {
    setIsIncoming(true);
    setMovementDirection(true);
  }

  const handleOut = () => {
    setIsIncoming(false);
    setMovementDirection(false);
  }

  const buttonStyles = { 
    boxShadow: 'none', 
    width: '100%', 
    "&:hover": {boxShadow: 'none'},
    fontWeight: 'semibold',
    fontSize: '14pt',
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 4,
        },
      }}
    >
      <ButtonGroup sx={{ width: '80%' }} size="large" aria-label="large button group" margin="normal">
        <Button 
          onClick={handleIn}
          sx={buttonStyles}
          color="success" 
          variant={isIncoming ? 'contained' : 'outlined'}>
            In
        </Button>
        <Button 
          onClick={handleOut} 
          sx={buttonStyles}
          color="error" 
          variant={!isIncoming ? 'contained' : 'outlined'}>
            Out
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default Switch;