import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ChangePasswordDialog from '../components/ChangePasswordDialog';
import NewTeamMemberDialog from '../components/NewTeamMemberDialog';

import { FetchAPI } from '../util/FetchAPI';



const TeamMembersList = () => {
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [changePasswordDialogState, setChangePasswordDialogState] = React.useState(false);
  const [newTeamMemberDialogState, setNewTeamMemberDialogState] = React.useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Change team members password dialog control
  const handlePasswordChangeButton = () => {
    setChangePasswordDialogState(true);
    setAnchorEl(null);
  };

  const getTeamMembers = React.useCallback(async () => {
    const res = await FetchAPI(`/teamMembers`, 'GET', '', localStorage.getItem("token"));
    switch (await res.status) {
      case 200:
        console.log(res.data);
        setTeamMembers(res.data)
        break;

      default:
        console.log(res.data.err);
        break;
    }
    return res.data;
  }, []);


  React.useEffect(() => {
    getTeamMembers();
  }, [getTeamMembers]);


  return (
  <div>
    <TableContainer component={Paper} variant="outlined">
      <Table sx={{ fontWeight: 'bold' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>TM #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>First name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Last name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>    
          {teamMembers.map((teamMember, i) => {
            return (
              <TableRow 
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">{teamMember.staffid}</TableCell>
                <TableCell>{teamMember.name.split(" ")[0]}</TableCell>
                <TableCell>{teamMember.name.split(" ")[1]}</TableCell>
                <TableCell align="right">
                  <IconButton 
                    sx={{marginRight: '12px'}}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpenMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handlePasswordChangeButton}>Change password</MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>

    <ChangePasswordDialog
      open={changePasswordDialogState} 
      handleClose={() => { setChangePasswordDialogState(false) }}
    />

    <NewTeamMemberDialog
      open={newTeamMemberDialogState} 
      handleClose={() => { setNewTeamMemberDialogState(false) }}
    />
  </div>
  );
}

export default TeamMembersList;
