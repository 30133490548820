import React, { useState } from 'react';
import { useParams } from 'react-router';
import { FetchAPI } from '../util/FetchAPI';
import { isMobile } from 'react-device-detect';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import {
  useNavigate
} from "react-router-dom";
import { Typography } from '@mui/material';


const Login = () => {
  const navigate = useNavigate();
  const { company } = useParams();

  const [idInput, setIdInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [inputErr, setInputErr] = useState(false);
  const [inputErrMsg, setErrMsg] = useState("");

  const handleFormSubmit = async () => {
    const loginType = isMobile ? "staff" : "store";
    const res = await FetchAPI('/login', 'POST', {
      clientid: idInput,
      type: loginType,
      company: company,
      password: passwordInput
    }, '');

    switch (await res.status) {
      case 200:
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("company", company);
        if (isMobile) navigate('/user');
        else navigate('/dashboard');
        break;
      case 401:
        setInputErr(true);
        setErrMsg(res.data.err);
        break;
      default:
        console.log(res.data.err);
        break;
    }
  }

  // React.useEffect(() => {
  //   FetchJB(194253408260)

  //   getHistory().then((data) => {
  //     setHistoryData(data);
  //   });
  // }, []);


  return (
    <Container fixed sx={{ 
      alignItems: 'center', 
      alignContent: 'center', 
      textAlign: 'center', 
      justifyContent: "center" }}>
        
      <Box 
        sx={{ 
          alignItems: 'center', 
          alignContent: 'center', 
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center" }}>

        <Box component={Paper} variant="outlined" sx={{padding: '30px', marginTop: '60px'}}>
          <Typography sx={{fontSize: "16pt"}}>Login</Typography>
          <div>
            <TextField 
              label={isMobile ? "Staff ID" : "Store ID"} 
              variant="outlined" 
              margin="normal"
              onChange={event => setIdInput(event.target.value)}
              fullWidth />
          </div>
          <div>
            <TextField 
              label="Password" 
              type="password" 
              variant="outlined" 
              margin="normal" 
              onChange={event => setPasswordInput(event.target.value)}
              fullWidth 
              error={inputErr}
              helperText={inputErrMsg}/>
          </div>
          
          <Button 
            variant="outlined" 
            sx={{marginTop: "20px", float: "right"}} 
            endIcon={<ArrowCircleRightIcon />}
            onClick={handleFormSubmit} >Login</Button>
        </Box> 
      </Box>
      
    </Container>
  );
}

export default Login;
