import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

// Date imports
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const HistoryFilter = ({
  currentLocation, 
  setCurrentLocation, 
  fetchHistory, 
  locations, 
  incomingCheck,
  setIncomingCheck, 
  outgoingCheck,
  setOutgoingCheck
}) => {
  const [dateFrom, setDateFrom] = React.useState(dayjs());
  const [dateTo, setDateTo] = React.useState(dayjs());


  const handleSelectChange = (event, newValue) => {
    setCurrentLocation(event.target.value);
    fetchHistory(event.target.value, dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD'));
  };


  const refreshMovements = () => {
    fetchHistory(currentLocation, dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD'))
  }

  return (
    <Paper variant="outlined" sx={{p: '28px'}}>
      <FormControl fullWidth sx={{mb: '20px'}}>
        <InputLabel id="select-label">Stock location</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={currentLocation}
          sx={{backgroundColor: '#FFF'}}
          label="Stock location"
          onChange={handleSelectChange}
        >
          <MenuItem value={-1}></MenuItem>
          {locations.map((location, index) => {
            return (
              <MenuItem value={location.id} key={index}>{location.name}</MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* Filter options group */}

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-au'} sx={{display: 'flex', flexDirection: 'row'}}>
        <DatePicker 
          sx={{width: '48%'}}
          label="Date from"
          value={dateFrom}
          onChange={(newValue) => setDateFrom(newValue)}
          onBlur={() => {console.log("date changed")}} />
        <DatePicker 
          sx={{width: '48%', marginLeft: '4%'}}
          label="Date to"
          value={dateTo}
          onChange={(newValue) => setDateTo(newValue)} />
      </LocalizationProvider>

      <FormGroup>
        <FormControlLabel control={
          <Checkbox 
            checked={incomingCheck}
            onChange={(event) => { setIncomingCheck(event.target.checked)}}
            defaultChecked />
          } label="Incoming" />
        <FormControlLabel control={
          <Checkbox 
            checked={outgoingCheck}
            onChange={(event) => { setOutgoingCheck(event.target.checked)}}
            defaultChecked />
          } label="Outgoing" />
      </FormGroup>

      <Button variant="contained" disableElevation sx={{ mb: '24px' }} onClick={refreshMovements}>
        Show movements
      </Button>

      <Divider />
      <Button variant="contained" disableElevation sx={{ mt: '24px' }}>
        Generate PDF
      </Button>
    </Paper>
  );
};

export default HistoryFilter;