import * as React from 'react';
import Button from '@mui/material/Button';
import { FetchAPI } from '../util/FetchAPI';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

export default function DeleteLocationDialog({refresh, locationId, closeMenu}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
    closeMenu();
  };

  const deleteLocation = async () => {
    const res = await FetchAPI(`/deleteLocation/${locationId}`, 'DELETE', {}, localStorage.getItem("token"));
    switch (await res.status) {
      case 200:
        handleClose();
        break;
      default:
        console.log(res.data.err);
        break;
    }
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>Delete</MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this location? This cannot be undone!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" disableElevation onClick={deleteLocation} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}