import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const ChangePasswordDialog = ({ handleClose, open}) => {
  const [newPassword, setNewPassword] = React.useState("");

  // Serial number input handler
  const handlePasswordChange = event => {
    setNewPassword(event.target.value);
  };

  const updatePassword = () => {

  }
  

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          <TextField 
            fullWidth 
            label="Change Password"
            type='password' 
            sx={{marginTop: '8px', backgroundColor: "#FFF" }}
            onChange={handlePasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            size="large"
            sx={{left: 0, right: 0, margin: 'auto', marginBottom: 1}}
            onClick={updatePassword}>update password</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangePasswordDialog;