import React from 'react';
import { FetchAPI } from '../util/FetchAPI';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import DeleteLocationDialog from '../components/DeleteLocationDialog';



const DashboardLocation = ({refresh, index, location, triggerMovementsCallback}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [editNameInput, setEditNameInput] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditButton = () => {
    setEditMode(true);
    setEditNameInput(location.name);
    handleCloseMenu();
  };

  const updateLocationName = async () => {
    const res = await FetchAPI(`/updateLocation/${location.id}`, 'PUT', {
      newLocationName: editNameInput
    }, localStorage.getItem("token"));
    switch (await res.status) {
      case 200:
        refresh();
        setEditMode(false);
        break;
      default:
        console.log(res.data.err);
        break;
    }
  };

  const viewMovements = () => {
    triggerMovementsCallback(location.id);
  };
  
  return (<div key={index}>
    <Grid container justifyContent="center" columnSpacing={4}>  
      <Grid item xs={12} md={6}>
        <Box 
          variant="outlined" 
          component={Paper} 
          sx={{ textAlign: 'left', marginBottom: '20px' }}
        >
          
          {!editMode && <Box sx={{display: 'inline-block', padding: '20px'}}>
            {location.name}
          </Box>}

          {editMode && 
            <TextField
            hiddenLabel
            autoFocus
            id="filled-hidden-label-small"
            sx={{margin: '4px', width: '40%'}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={updateLocationName}>
                    <DoneIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            defaultValue={location.name}
            onChange={(event) => {setEditNameInput(event.target.value)}}
            />
          }
          
          <Box sx={{float: 'right'}}>
            <IconButton 
              sx={{marginRight: '12px'}}
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEditButton}>Edit</MenuItem>
              <DeleteLocationDialog 
                closeMenu={handleCloseMenu}
                locationId={location.id}
                refresh={refresh} />
            </Menu>

            <Button sx={{
              padding: '20px',
              boxShadow: 'none', 
              "&:hover": {boxShadow: 'none'},
              fontWeight: 'semibold'}} 
              variant="contained"
              onClick={viewMovements}
            >Movements</Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </div>);
}

export default DashboardLocation;