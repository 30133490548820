import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import { FetchAPI } from '../util/FetchAPI';

import PasswordIcon from '@mui/icons-material/Password';


const SettingsDialog = ({ handleClose, open, logout}) => {
  const [serialInput, setSerialInput] = React.useState("");
  const [nameInput, setNameInput] = React.useState("");

  // Serial number input handler
  const handleSerialChange = event => {
    setSerialInput(event.target.value);
  };
  

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <TextField 
            fullWidth 
            label="Change Password"
            type='password' 
            sx={{marginTop: '8px', backgroundColor: "#FFF" }}
            onChange={handleSerialChange}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            color="error" 
            variant="outlined" 
            size="large"
            sx={{left: 0, right: 0, margin: 'auto', marginBottom: 1}}
            onClick={logout}>logout</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingsDialog;