import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle';
import { FetchAPI } from '../util/FetchAPI';

import QrCodeIcon from '@mui/icons-material/QrCode';
import PersonIcon from '@mui/icons-material/Person';


const MovementDialog = ({ sku, productTitle, incoming, open, handleClose, locationid }) => {
  const [serialInput, setSerialInput] = React.useState("");
  const serialInputRef = useRef();

  // Serial number input handler
  const handleSerialChange = event => {
    setSerialInput(event.target.value);
  };


  const handleSubmit = async () => {
    const res = await FetchAPI('/movement', 'POST', {
      sku: sku,
      serial: serialInput,
      product: productTitle,
      incoming: incoming,
      locationid: locationid
    }, localStorage.getItem("token"));

    switch (await res.status) {
      case 200:
        setSerialInput("");
        handleClose();
        break;
      default:
        console.log(res.data.err);
        break;
    }
  };

  useEffect(() => {
    if (serialInputRef.current) {
      serialInputRef.current.focus();
    }

    function handleResize() {
      //open = open;
    }

    // Attach the event listener to the window object
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter stock movement details</DialogTitle>
        <DialogContent>
          <TextField 
            fullWidth 
            autoFocus
            ref={serialInputRef}
            label="Scan or type serial number" 
            sx={{ marginBottom: '32px', marginTop: '8px', backgroundColor: "#FFF" }}
            onChange={handleSerialChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <QrCodeIcon />
                </InputAdornment>
              )
            }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MovementDialog;