//import backendConfig from './config.json';

export const FetchJB = async (barcode) => {

  try {
    var postData = JSON.stringify({"requests":[{"indexName":"shopify_products_families","params":`clickAnalytics=true&distinct=true&facets=%5B%22facets.Price%22%2C%22onPromotion%22%5D&filters=(price%20%3E%200%20AND%20product_published%20%3D%201%20AND%20availability.displayProduct%20%3D%201)&highlightPostTag=%3C%2Fais-highlight-0000000000%3E&highlightPreTag=%3Cais-highlight-0000000000%3E&hitsPerPage=4&query=${barcode}&tagFilters=`}]});

    const res = await fetch('https://vtvkm5urpx-1.algolianet.com/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20JavaScript%20(3.35.1);%20Browser%20(lite);%20instantsearch.js%202.10.5;%20JS%20Helper%20(2.28.0)&x-algolia-application-id=VTVKM5URPX&x-algolia-api-key=a0c0108d737ad5ab54a0e2da900bf040', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: postData,
    })
    console.log(res.json);
    return res.json()

  } catch (e) {
    console.error(`Network error: ${e}`);
  }
}


// formdata
