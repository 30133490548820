import React from 'react';
import { useParams } from 'react-router';
import { FetchAPI } from '../util/FetchAPI';
import { FetchJB } from '../util/FetchJB';
import {
  useNavigate
} from "react-router-dom";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import IncomingSwitch from '../components/Switch';
import HistoryList from '../components/HistoryList';
import MovementDialog from '../components/MovementDialog';
import ScannerDialog from '../components/ScannerDialog';

import CropFreeIcon from '@mui/icons-material/CropFree';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-au';


const History = () => {
  const navigate = useNavigate();
  const { locationid } = useParams();

  const [movementDialogState, setMovementDialogState] = React.useState(false);
  const [scannerDialogState, setScannerDialogState] = React.useState(false);

  const [productBarcodeInput, setProductBarcodeInput] = React.useState("");
  const [newMovementSku, setNewMovementSku] = React.useState("");
  const [newMovementTitle, setNewMovementTitle] = React.useState("");
  const [incoming, setIncoming] = React.useState(true);
  const [historyData, setHistoryData] = React.useState([]);
  


  // Scanner dialog control
  const handleOpenScanner = ({logout}) => {
    setScannerDialogState(true);
  };

  const handleCloseScanner = () => {
    setScannerDialogState(false);
  };

  // New movement form dialog controls
  const handleOpenMovement = () => {
    FetchJB(productBarcodeInput).then(data => {
      const relevantData = data.results[0]['hits'][0];
      console.log(relevantData);
      if (relevantData) {
        setNewMovementSku(`${relevantData.sku} - ${relevantData.title}`);
        setNewMovementTitle(relevantData.category);
      } else {
        setNewMovementSku(productBarcodeInput);
        setNewMovementTitle("Unknown product");
      }
    })
    setMovementDialogState(true);
  };

  const handleCloseMovement = () => {
    setProductBarcodeInput("");
    setNewMovementSku("");
    setNewMovementTitle("");
    setMovementDialogState(false);
    getHistory().then((data) => {
      setHistoryData(data);
    });
  };

  // Go back to locations list
  const handleBack = () => {
    navigate("/user");
  };

  
  // Barcode input handler
  const handleChange = event => {
    setProductBarcodeInput(event.target.value);
  };

  const getHistory = React.useCallback(async () => {
    const res = await FetchAPI(`/history/${locationid}?d1=${dayjs().format('YYYY-MM-DD')}&d2=${dayjs().format('YYYY-MM-DD')}`, 'GET', '', localStorage.getItem("token"));
    switch (await res.status) {
      case 200:
        setHistoryData(res.data);
        break;
      case 401:
        localStorage.removeItem("token");
        navigate(`/login/${localStorage.getItem("company")}`);
        break;

      default:
        console.log(res.data.err);
        break;
    }
    return res.data;
  }, [locationid]);

  const focusUsernameInputField = input => {
    if (input) setTimeout(() => {input.focus()}, 100);
  };

  React.useEffect(() => {
    getHistory();
  }, [getHistory, locationid]);


  return (
    <Container fixed sx={{ alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
      <MovementDialog 
        sku={newMovementSku}
        productTitle={newMovementTitle}
        incoming={incoming}
        locationid={locationid}
        open={movementDialogState} 
        handleClose={handleCloseMovement} 
        openScanner={handleOpenScanner} 
      />

      <ScannerDialog 
        open={scannerDialogState}
        handleClose={handleCloseScanner}
      />

      <Box><Typography variant="h6" sx={{marginTop: 2}}>{localStorage.getItem("location")}</Typography></Box>

      <IconButton 
        aria-label="back" 
        size="large" 
        onClick={handleBack}
        sx={{left: 0, top: 0, margin: 1, position: 'fixed'}}>
        <ArrowBackIcon fontSize="inherit" />
      </IconButton>

      <IncomingSwitch setMovementDirection={setIncoming} />


      <TextField 
        fullWidth 
        autoFocus
        inputRef={focusUsernameInputField}
        label="Scan or type barcode" 
        sx={{ marginBottom: '32px', backgroundColor: "#FFF" }}
        onChange={handleChange}
        value={productBarcodeInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CropFreeIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="contained" onClick={handleOpenMovement}>
                Next
              </Button>
            </InputAdornment>
          ),
        }} />

      <HistoryList historyData={historyData} />
      
    </Container>
  );
}

export default History;
