import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Scanner from './Scanner';


const ScannerDialog = ({ open, handleClose }) => {
  

  return (
    <div>
      
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Scan product barcode</DialogTitle>
        <DialogContent>
          <Scanner />
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScannerDialog;