import React from 'react';
import { FetchAPI } from '../util/FetchAPI';
import { FetchJB } from '../util/FetchJB';
import SettingsDialog from '../components/SettingsDialog';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import StoreIcon from '@mui/icons-material/Store';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  useNavigate
} from "react-router-dom";


const User = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = React.useState([]);
  const [settingsDialogState, setSettingsDialogState] = React.useState(false);

  const getLocations = async () => {
    const res = await FetchAPI("/locations", 'GET', '', localStorage.getItem("token"));
    switch (await res.status) {
      case 200:
        return res.data;
      case 401:
        handleLogout();
    }
    return res.data;
  }

  // Settings dialog control
  const handleOpenSettings = () => {
    setSettingsDialogState(true);
  };
  const handleCloseSettings = () => {
    setSettingsDialogState(false);
  };

  const handleLogout = async () => {
    await FetchAPI(`/logout`, 'POST', {}, localStorage.getItem("token"));
    localStorage.removeItem("token");
    navigate(`/login/${localStorage.getItem("company")}`);
  };


  React.useEffect(() => {

    getLocations().then((data) => {
      console.log(data);
      setLocations(data);
    });
  }, []);


  return (
    <div>
      <AppBar position="static" sx={{marginBottom: "20px", boxShadow: "none"}}>
        <Toolbar sx={{backgroundColor: "#EEE", color: "#111", borderBottom: 1, borderColor: 'divider'}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <StoreIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {localStorage.getItem("company")}
          </Typography>

          <IconButton 
            aria-label="settings" 
            size="large"
            onClick={handleOpenSettings}
            sx={{float: 'right', right: '0', marginLeft: 'auto'}}>
            <SettingsIcon fontSize="inherit" />
          </IconButton>
          
        </Toolbar>
      </AppBar>

      <SettingsDialog 
        open={settingsDialogState} 
        handleClose={handleCloseSettings}
        logout={handleLogout}
      />

      <Container sx={{ alignItems: 'center', alignContent: 'center', textAlign: 'center' }}>
        {locations.map((location, index) => {
          return (<div key={index}>
            <Box 
              variant="outlined" 
              component={Paper} 
              sx={{ padding: '20px', textAlign: 'left', marginBottom: '20px'}}
              onClick={() => {
                localStorage.setItem("location", location.name)
                navigate("/history/" + location.id)}}>
              {location.name}
            </Box>
          </div>);
        })}
      </Container>
    </div>
  );
}

export default User;
