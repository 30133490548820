import React from 'react';
import Moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import SellIcon from '@mui/icons-material/Sell';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs';

const HistoryList = ({ historyData }) => {
  
  const [open, setOpen] = React.useState(-1);
  const [historyDataSorted, setHistoryDataSorted] = React.useState([]);

  const SortMovements = () => {
    let finalObj = {}
    historyData.forEach((m) => {
      const date = m.created_at.split('T')[0]
      if (finalObj[date]) {
        finalObj[date].push(m);
      } else {
        finalObj[date] = [m];
      }
    })
    console.log(finalObj)
    setHistoryDataSorted(Object.entries(finalObj))
  }

  const handleClick = (id) => {
    let idToOpen = id;
    if (id === open) idToOpen = -1;
    setOpen(idToOpen);
  };

  React.useEffect(() => {
    setOpen(-1);
    SortMovements();
  }, [setOpen, historyData]);


  return (<div>
    {historyDataSorted.length !== 0 ? "" : 
      <Box component={Paper} variant="outlined" sx={{ p: "32px", textAlign: "center"}}>
        No Movements to show
      </Box>
    }

    {historyDataSorted.map((thisDaysMovements, indexDays) => {
      return (<div key={indexDays}>
        <Chip 
          label={dayjs(thisDaysMovements[0]).format("DD / MM")} />
          
        <Box component={Paper} variant="outlined" sx={{m: "12px 0 24px 0"}}>
          <List variant="outlined" sx={{margin: 0, padding: 0}}>
            {thisDaysMovements[1].map((movement, indexMovements) => {
              return (<div key={indexMovements}>
                <ListItem sx={{margin: 0, padding: 0}}>
                  <ListItemButton onClick={() => handleClick(movement.id)}>
                    <ListItemText primary={Moment(movement.created_at).utcOffset('+0000').format('HH:mm')} />
                    <ListItemText primary={movement.product} />
                    <ListItemIcon sx={{margin: 0, padding: 0}}>
                      {movement.incoming 
                      ? <InputIcon color="success" sx={{margin: 0, padding: 0}} /> 
                      : <OutputIcon color="error" sx={{margin: 0, padding: 0}} />}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>

                <Collapse in={movement.id === open}>
                  <List>
                    <ListItem sx={{marginLeft: "0.5rem"}}>
                      <ListItemIcon>
                        <SellIcon />
                      </ListItemIcon>
                      {movement.sku}
                    </ListItem>
                    <ListItem sx={{marginLeft: "0.5rem"}}>
                      <ListItemIcon>
                        <NumbersIcon />
                      </ListItemIcon>
                      {movement.serial}
                    </ListItem>
                    <ListItem sx={{marginLeft: "0.5rem"}}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      {movement.employee_name}
                    </ListItem>
                  </List>
                </Collapse>
                <Divider />
              </div>);
            })}
          </List>
        </Box></div>);
      })}
    </div>
  );
};

export default HistoryList;