import './App.css';
import React from 'react';
import User from './pages/User';
import Login from './pages/Login';
import History from './pages/History';
import Dashboard from './pages/Dashboard';

import {
  BrowserRouter as Router,
  Routes,
  Route, 
  Navigate
  //Link
} from "react-router-dom";


function App() {

  return (
    <Router>
      <div>
        <Routes>
          <Route path='/user' element={<User/>} />
          <Route path="/login/:company" element={<Login/>}  />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/history/:locationid" element={<History />} />
          {/* <Route path="/" element={navigate("/login/jb")} /> */}
          <Route path="*" element={<Navigate to="/login/jb" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
