import * as React from 'react';
import Button from '@mui/material/Button';
import { FetchAPI } from '../util/FetchAPI';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

export default function NewLocationDialog({refresh}) {
  const [open, setOpen] = React.useState(false);
  const [locationNameInput, setLocationNameInput] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitNewLocation = async () => {
    const res = await FetchAPI(`/newLocation`, 'POST', {
      name: locationNameInput,
    }, localStorage.getItem("token"));
    
    switch (await res.status) {
      case 200:
        refresh();
        setOpen(false);
        break;
      default:
        console.log(res.data.err);
        break;
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <Button variant="outlined" size="large" onClick={handleClickOpen}>
          Add new stock location
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New stock location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the new stock location.
          </DialogContentText>
          <TextField
          sx={{ marginTop: '20px' }}
            autoFocus
            id="name"
            label="New location name"
            fullWidth
            variant="outlined"
            onChange={(event) => {setLocationNameInput(event.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" disableElevation onClick={submitNewLocation}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}